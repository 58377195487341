import React from 'react';
import './VgroupBookingModification.css';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaSyncAlt, FaStream, FaHeadset, FaInfoCircle,  } from 'react-icons/fa';
import NumberPopup from '../NumberPopup/NumberPopup';
import Bookingslanding from '../Bookings/Bookingslanding.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import image from '../../Media/136431.jpg';
import '@fortawesome/fontawesome-free/css/all.min.css';
const VgroupBookingModification = () => {

  const handlePhoneClick = () => {
    // Google Ads conversion tracking for phone calls
    window.gtag('config', 'AW-16610863777/ED5wCNmkn9QZEKHV1vA9', {
      'phone_conversion_number': '(866) 410-4797'
    });
  
    // Initiate the phone call
    window.location.href = 'tel:+18664104797';
  };
  
  
  
  return (
    <div>
            <div className="landing-page-1-container">
        <div className="landing-page-1-card">
          <h2 className="landing-page-1-title">Airline Reservation Desk </h2>
          <h2 className="landing-page-1-title">24/7 Helpline ( Toll-free )</h2>
          <img
            src={image} 
            alt="Flight Attendant"
            className="landing-page-1-image"
          />
          <button  className="landing-page-1-cta-button"  href="tel:+18664104797" onClick={handlePhoneClick} >Booking, Changes & Cancellation</button>
          <div className="landing-page-1-call-info">
            <p>No Hold – Call Answered In 5 Sec</p>
            <a href="tel:+18664104797" className="landing-page-1-phone-number">
              <div className="landing-page-1-phone-box">
                {/* Animated Font Awesome Icon */}
                <FontAwesomeIcon
                  icon={faPhone}
                  className="landing-page-1-phone-icon fa-bounce" // Add bounce animation
                /> (866) 410-4797
              </div>
            </a>
            <p>24/7 Helpline</p>
          </div>
        </div>
      </div>
      <Bookingslanding />
      <div className="VgroupBookingModification-container">

        {/* Existing content */}
        <section className="VgroupBookingModification-intro">
          <p>Plans change, and we understand that. At Vgroup Ventures LLC, we strive to make adjusting your travel plans as smooth and straightforward as booking your flight. Our flight change service is designed to provide you with flexibility and convenience, ensuring your travel experience remains stress-free.</p>
        </section>

        <Container className="flight-booking-modification-container">
          <h2 className="text-center mb-4">Why Choose Vgroup Ventures LLC for Flight Changes?</h2>
          <Row className="justify-content-center">
            <Col md={3} className="mb-4">
              <Card className="flight-booking-modification-card">
                <Card.Body className="text-center">
                  <FaSyncAlt size={50} className="flight-booking-modification-icon mb-3" />
                  <Card.Title>Flexible Options</Card.Title>
                  <Card.Text>
                    Whether you need to change your travel dates, update passenger details, or switch flights, we offer a range of options to accommodate your needs.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="mb-4">
              <Card className="flight-booking-modification-card">
                <Card.Body className="text-center">
                  <FaStream size={50} className="flight-booking-modification-icon mb-3" />
                  <Card.Title>Efficient Process</Card.Title>
                  <Card.Text>
                    Our streamlined process makes it easy to modify your booking. With our user-friendly platform, you can manage your changes quickly and efficiently.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="mb-4">
              <Card className="flight-booking-modification-card">
                <Card.Body className="text-center">
                  <FaHeadset size={50} className="flight-booking-modification-icon mb-3" />
                  <Card.Title>Expert Assistance</Card.Title>
                  <Card.Text>
                    Our customer support team is available 24/7 to help you with any modifications. We provide guidance and support throughout the entire process.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="mb-4">
              <Card className="flight-booking-modification-card">
                <Card.Body className="text-center">
                  <FaInfoCircle size={50} className="flight-booking-modification-icon mb-3" />
                  <Card.Title>Transparent Policies</Card.Title>
                  <Card.Text>
                    We ensure you are fully informed about any fees or conditions associated with changing your flight, so there are no surprises.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <section className="VgroupBookingModification-how-it-works">
          <h2>How It Works</h2>
          <div className="VgroupBookingModification-steps-grid">
            <div className="VgroupBookingModification-step-card">
              <div className="VgroupBookingModification-step-number">1</div>
              <h3>Access Your Booking</h3>
              <p>Log in to your Vgroup Ventures LLC account and navigate to the "Manage Booking" section. Enter your booking reference number and the email address used for the reservation.</p>
            </div>
            <div className="VgroupBookingModification-step-card">
              <div className="VgroupBookingModification-step-number">2</div>
              <h3>Select Changes</h3>
              <p>Choose the modifications you need to make. Options may include changing flight dates, updating passenger information, or selecting a different flight.</p>
            </div>
            <div className="VgroupBookingModification-step-card">
              <div className="VgroupBookingModification-step-number">3</div>
              <h3>Review and Confirm</h3>
              <p>Review the changes and any applicable fees or fare differences. Confirm the modifications and proceed to payment if necessary.</p>
            </div>
            <div className="VgroupBookingModification-step-card">
              <div className="VgroupBookingModification-step-number">4</div>
              <h3>Receive Updated Confirmation</h3>
              <p>Once your changes are processed, you will receive a new confirmation email with your updated travel details.</p>
            </div>
          </div>
        </section>

        <section className="VgroupBookingModification-key-features">
          <h2>Key Features</h2>
          <ul className="VgroupBookingModification-feature-list">
            <li>Date Flexibility: Easily change your travel dates to better suit your schedule.</li>
            <li>Passenger Updates: Modify passenger details, such as names or contact information.</li>
            <li>Flight Selection: Switch to a different flight that better fits your plans.</li>
            <li>Additional Services: Add or update additional services, such as seat selection, extra baggage, or meal preferences.</li>
          </ul>
        </section>

        <section className="VgroupBookingModification-important-info">
          <h2>Important Information</h2>
          <ul className="VgroupBookingModification-info-list">
            <li>Change Fees: Depending on the airline and fare type, change fees may apply. We provide transparent information about any costs involved.</li>
            <li>Fare Differences: If the new flight has a higher fare, you will need to pay the difference. If the fare is lower, we will refund the difference according to airline policies.</li>
            <li>Policy Compliance: All changes are subject to the airline's policies and availability. We work closely with our airline partners to ensure smooth modifications.</li>
          </ul>
        </section>

        <footer className="VgroupBookingModification-footer">
          <h2>Experience Flexibility with Vgroup Ventures LLC</h2>
          <p>At Vgroup Ventures LLC, we prioritize your convenience and satisfaction. We understand that travel plans can change, and we're here to make those changes as easy as possible. Trust us to handle your flight modifications with care and efficiency.</p>
        </footer>
      </div>


      <div className="VgroupBookingModification-container">
            {/* New content */}
            <section className="VgroupBookingModification-intro">
          <h2>Change or Cancel Your Flight Ticket with VGroup Ventures</h2>
          <p>Welcome to VGroup Ventures, where we understand that flexibility is essential when travel plans evolve. Whether you need to modify or cancel your airline ticket, switch to a different flight, or upgrade to first or business class, we provide comprehensive solutions and dedicated support to ensure your journey remains seamless.</p>
        </section>

        <section className="VgroupBookingModification-effortless">
          <h2>Effortless Flight Ticket Change and Cancellation</h2>
          <p>Changing or cancelling your flight with VGroup Ventures is designed to be straightforward and convenient. Our team of knowledgeable agents is available 24/7 to assist you through every step of the process, ensuring a hassle-free experience tailored to your preferences.</p>
        </section>

        <section className="VgroupBookingModification-personalized-assistance">
          <h2>Personalized Assistance</h2>
          <p>When you call our flight change phone number or flight cancellation phone number, you'll connect directly with a friendly and experienced agent who can assist with:</p>
          <ul>
            <li><strong>Flight Changes:</strong> Explore alternative flight schedules, upgrade your seat to first or business class, and receive real-time updates on availability and booking changes.</li>
            <li><strong>Flight Cancellation:</strong> If your plans change and you need to cancel your flight, our agents will guide you through understanding cancellation policies, refund options, and any associated fees.</li>
          </ul>
        </section>

        <section className="VgroupBookingModification-upgrade">
          <h2>Upgrade Your Travel Experience</h2>
          <p>Consider enhancing your journey by upgrading to first or business class. Enjoy benefits such as priority check-in, spacious seating, enhanced dining options, and exclusive amenities tailored to make your travel experience comfortable and enjoyable.</p>
        </section>

        <section className="VgroupBookingModification-peace-of-mind">
          <h2>Peace of Mind</h2>
          <p>With VGroup Ventures, you can modify or cancel your flight booking confidently. Our secure booking platform ensures your personal information remains safe throughout the process, providing you with peace of mind as you adjust your travel plans.</p>
        </section>

        <section className="VgroupBookingModification-contact">
          <h2>Contact Us</h2>
          <p>Whether you need to modify your flight details or cancel your booking, our dedicated team is ready to assist. Call our flight change phone number or flight cancellation phone number, or visit our website to connect with a travel expert who can help you navigate your options effectively.</p>
          <button className="VgroupBookingModification-contact-button"  onClick={handlePhoneClick}>Modify or Cancel Your Flight</button>

        </section>

      </div>
          

      <NumberPopup />
    </div>
  );
};

export default VgroupBookingModification;
