import React from 'react';
import './AirlineReservations.css';
import {  FaPlane, FaFireAlt, FaHeadset, FaPhoneAlt, FaCheckCircle} from 'react-icons/fa';
import AirlineReservationsSearch from './AirlineReservationsSearch';
import agent from'../../Media/111.webp'
import banner from '../../Media/8338.jpg'


const AirlineReservations = () => {

    const handlePhoneClick = () => {
        // Google Ads conversion tracking for phone calls
        window.gtag('config', 'AW-16610863777/ED5wCNmkn9QZEKHV1vA9', {
          'phone_conversion_number': '(866) 410-4797'
        });
      
        // Initiate the phone call
        window.location.href = 'tel:+18664104797';
      };

      

    return (
        <>
            <div className="Airline-Reservations-container">
                <div className="Airline-Reservations-left">
                    {/* <FaPlane className="Airline-Reservations-icon" />
                    <span className="Airline-Reservations-text">Reserve Cheap Flights Online</span> */}
                </div>
                <div className="Airline-Reservations-right">
                    <div className="Airline-Reservations-arc-logo">
                        <span className="Airline-Reservations-arc">ARC</span>
                        <span className="Airline-Reservations-accredited">Accredited Agency</span>
                    </div>
                    <span className="Airline-Reservations-message">
                        SOAR CONFIDENTLY WITH OUR ARC ACCREDITED TRAVEL SERVICES
                    </span>
                </div>
            </div>

            <section className="Airline-Reservations-banner-container">
                <div className="Airline-Reservations-banner-overlay">
                    <div className="Airline-Reservations-banner-content">
                        <h2 className="Airline-Reservations-banner-title">
                            24/7 Airline Reservations & Support Desk
                        </h2>
                        <p className="Airline-Reservations-banner-subtitle">
                            We’ll beat any online ticket price.
                        </p>
                        <div className="Airline-Reservations-banner-contact">
                            <button className="Airline-Reservations-banner-contact-button" onClick={handlePhoneClick}>
                                <span  role="img" aria-label="phone">📞</span> (866) 410-4797
                            </button>
                        </div>
                        <div className="Airline-Reservations-banner-support-info">
                            24/7 SUPPORT | NO HOLD TIME
                        </div>
                    </div>
                </div>
            </section>



            <div className="Airline-Reservations-banner-mobile-container">
      <div className="Airline-Reservations-banner-mobile-content">
        <h1 className="Airline-Reservations-banner-mobile-title">
          24/7 Airline Reservations & Support Desk
        </h1>
        <p className="Airline-Reservations-banner-mobile-subtitle">
          WE'LL BEAT ANY ONLINE TICKET PRICE.
        </p>
        <img
          className="Airline-Reservations-banner-mobile-image"
          src={banner} // Replace with the actual image URL
          alt="Airplane"
        />
        <div className="Airline-Reservations-banner-mobile-contact">
          <p className="Airline-Reservations-banner-mobile-support">
            24/7 Support &nbsp; | &nbsp; No Hold Times
          </p>
          <button className="Airline-Reservations-banner-mobile-button" onClick={handlePhoneClick}>
            <i className="fas fa-phone-alt"></i>  (866) 410-4797
          </button>
        </div>
      </div>
    </div>




            <AirlineReservationsSearch/>

            <div className="Airline-Reservations-section-1-container">
      <div className="Airline-Reservations-section-1-item">
        <FaPlane className="Airline-Reservations-section-1-icon" />
        <div>
          <h3 className="Airline-Reservations-section-1-title">Book Your Flight</h3>
          <p className="Airline-Reservations-section-1-description">
            Book a flight with us and enjoy an authentic flying experience.
          </p>
        </div>
      </div>

      <div className="Airline-Reservations-section-1-item">
        <FaFireAlt className="Airline-Reservations-section-1-icon" />
        <div>
          <h3 className="Airline-Reservations-section-1-title">Unpublished Deals</h3>
          <p className="Airline-Reservations-section-1-description">
            Grab exclusive and unpublished airline deals only offered on our website.
          </p>
        </div>
      </div>

      <div className="Airline-Reservations-section-1-item">
        <FaHeadset className="Airline-Reservations-section-1-icon" />
        <div>
          <h3 className="Airline-Reservations-section-1-title">24/7 Hours Support Desk</h3>
          <p className="Airline-Reservations-section-1-description">
            Make any changes to your flight, cancel it, or get a refund with lightning speed.
            Our customer support is standing by.
          </p>
        </div>
      </div>
    </div>

    <div className="Airline-Reservations-section-2-container">
      <p className="Airline-Reservations-section-2-description">
        People love our Platform because it’s so easy to use and has so many extra features, but that’s only one of many reasons. Clients speak about the Platform for the following reasons:
      </p>
      <ul className="Airline-Reservations-section-2-benefits">
        <li><FaCheckCircle className="Airline-Reservations-section-2-benefit-icon" /> No wait time</li>
        <li><FaCheckCircle className="Airline-Reservations-section-2-benefit-icon" /> No hidden charges</li>
        <li><FaCheckCircle className="Airline-Reservations-section-2-benefit-icon" /> Knowledgeable customer support</li>
        <li><FaCheckCircle className="Airline-Reservations-section-2-benefit-icon" /> 24/7 Online support</li>
      </ul>
      <div className="Airline-Reservations-section-2-helpline" onClick={handlePhoneClick}>
        <FaPhoneAlt className="Airline-Reservations-section-2-phone-icon" />
        <span className="Airline-Reservations-section-2-phone-number">(866) 410-4797</span>
      </div>
      <p className="Airline-Reservations-section-2-helpline-info">24/7 HELPLINE | NO HOLD TIME</p>
      <div className="Airline-Reservations-section-2-agent">
        <img src={agent} alt="Agent" className="Airline-Reservations-section-2-agent-photo" />
        <span className="Airline-Reservations-section-2-agent-status">
          <span className="Airline-Reservations-section-2-status-indicator"></span> Agent is available now.
        </span>
      </div>
    </div>

            
        </>
    );
};

export default AirlineReservations;
