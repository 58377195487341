import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faCalendarAlt, faCheckCircle, faPlane, faPhone } from '@fortawesome/free-solid-svg-icons';
import './BookAFlight.css';  // Import the CSS file for styling
import images from '../../Media/travels.jpg'
import wt from '../../Media/wt.webp'
import wts from '../../Media/custom.webp'
import ci from '../../Media/ci.webp'
import loader from '../../Media/loader.png'
import tkt from '../../Media/tkt.png'
import comm from '../../Media/comm.jpg'
import NumberPopup from '../NumberPopup/NumberPopup';
import Bookingslanding from '../Bookings/Bookingslanding.jsx';
import image from '../../Media/136431.jpg';



const handlePhoneClick = () => {
  // Google Ads conversion tracking for phone calls
  window.gtag('config', 'AW-16610863777/ED5wCNmkn9QZEKHV1vA9', {
    'phone_conversion_number': '(866) 410-4797'
  });

  // Initiate the phone call
  window.location.href = 'tel:+18664104797';
};



const BookAFlight = () => {
  return (
    <>
          <div className="landing-page-1-container">
        <div className="landing-page-1-card">
          <h2 className="landing-page-1-title">Airline Reservation Desk </h2>
          <h2 className="landing-page-1-title">24/7 Helpline ( Toll-free )</h2>
          <img
            src={image} 
            alt="Flight Attendant"
            className="landing-page-1-image"
          />
          <button  className="landing-page-1-cta-button"  href="tel:+18664104797" onClick={handlePhoneClick} >Booking, Changes & Cancellation</button>
          <div className="landing-page-1-call-info">
            <p>No Hold – Call Answered In 5 Sec</p>
            <a href="tel:+18664104797" className="landing-page-1-phone-number">
              <div className="landing-page-1-phone-box">
                {/* Animated Font Awesome Icon */}
                <FontAwesomeIcon
                  icon={faPhone}
                  className="landing-page-1-phone-icon fa-bounce" // Add bounce animation
                /> (866) 410-4797
              </div>
            </a>
            <p>24/7 Helpline</p>
          </div>
        </div>
      </div>
      <Bookingslanding/>
      <div className="book-a-flight">
        <div className="book-a-flight-hero-section">
          <h1>Book Your Flight with V Group Ventures</h1>
          <p>Your journey starts here. Let us help you find the best flight options with ease and convenience.</p>
        </div>

        <div className="book-a-flight-content-section">
          <div className="book-a-flight-grid-container">
            <div className="book-a-flight-grid-item">
              <FontAwesomeIcon icon={faPhoneAlt} size="3x" />
              <h2>Call Us</h2>
              <p>Reach out to our booking hotline at <a href="tel:+18664104797" onClick={handlePhoneClick}>(866) 410-4797</a> for personalized service and the best flight options.</p>
            </div>
            <div className="book-a-flight-grid-item">
              <FontAwesomeIcon icon={faCalendarAlt} size="3x" />
              <h2>Provide Your Details</h2>
              <p>Share your travel details, including departure and destination cities, travel dates, and the number of passengers.</p>
            </div>
            <div className="book-a-flight-grid-item">
              <FontAwesomeIcon icon={faPlane} size="3x" />
              <h2>Receive Options</h2>
              <p>We will provide you with the best flight options available based on your preferences.</p>
            </div>
            <div className="book-a-flight-grid-item">
              <FontAwesomeIcon icon={faCheckCircle} size="3x" />
              <h2>Confirm Your Booking</h2>
              <p>Confirm your flight choice and complete the payment process over the phone.</p>
            </div>
          </div>

          <div className="book-a-flight-image-section">
            <img src={images} alt="Flight Booking" /> {/* Replace with your image URL */}
          </div>

          <div className="book-a-flight-travel-tips">
            <h2>Travel Tips</h2>
            <ul>
              <li><FontAwesomeIcon icon={faCheckCircle} /> Check-in Online to save time at the airport.</li>
              <li><FontAwesomeIcon icon={faCheckCircle} /> Arrive at the airport early: 2 hours for domestic, 3 hours for international flights.</li>
              <li><FontAwesomeIcon icon={faCheckCircle} /> Carry all necessary documents, including your passport and e-ticket.</li>
              <li><FontAwesomeIcon icon={faCheckCircle} /> Follow the airline's baggage policy and pack accordingly.</li>
            </ul>
          </div>
          
          {/* Adding new content */}
          <div className="book-a-flight-alternate-section">
            <div className="book-a-flight-text">
              <h2>Welcome to VGroup Ventures</h2>
              <p>Welcome to VGroup Ventures, your premier destination for seamless flight bookings and airline reservations. Whether you're in need of international tickets, domestic travel options, or premium seats like first class and business class flight tickets, we ensure a stress-free booking experience with exceptional benefits:</p>
            </div>
            <div className="book-a-flight-image">
              <img src={wt} alt="Flight Booking" />
            </div>
          </div>

          <div className="book-a-flight-alternate-section">
            <div className="book-a-flight-image">
              <img src={loader} alt="Flight Booking" />
            </div>
            <div className="book-a-flight-text">
              <h2>Efficient Booking Process</h2>
              <p>Save time with our user-friendly website and mobile app, designed for quick and hassle-free flight bookings. Compare multiple airlines, choose the best fares for your schedule, and confirm your airline tickets within minutes.</p>
              <h2>Competitive Pricing</h2>
              <p>We offer competitive prices on all types of tickets, from economy to first class. Our partnerships with major airlines enable us to provide you with the best deals available.</p>
            </div>
          </div>

          <div className="book-a-flight-alternate-section">
            <div className="book-a-flight-text">
              <h2>Excellent Customer Service</h2>
              <p>Our dedicated customer service team is available round-the-clock to assist you. Whether you have questions about your flight booking or need assistance with changes, our airline reservations phone number is there to provide you with personalized support.</p>
              <h2>Secure Booking</h2>
              <p>Rest assured, your personal information and payment details are safe with our secure booking platform. Book with confidence knowing your data is protected.</p>
            </div>
            <div className="book-a-flight-image">
              <img src={wts} alt="Flight Booking" />
            </div>
          </div>

          <div className="book-a-flight-alternate-section">
            <div className="book-a-flight-image">
              <img src={ci} alt="Flight Booking" />
            </div>
            <div className="book-a-flight-text">
              <h2>Customized Travel Solutions</h2>
              <p>Tailor your travel plans with our flexible booking options. Whether you're planning a last-minute getaway or a well-organized business trip, we have the resources and expertise to meet your needs.</p>
              <h2>Travel Tips and Resources</h2>
              <p>Explore our travel tips and destination guides to enhance your travel experience. From packing tips to local insights, we provide the information you need to make the most of your journey.</p>
            </div>
          </div>

          <div className="book-a-flight-alternate-section">
            <div className="book-a-flight-text">
              <h2>Major Airlines Policies</h2>
              <p>When booking with VGroup Ventures, you can access detailed information on policies from major USA airlines:</p>
              <ul>
                <li><FontAwesomeIcon icon={faCheckCircle} /> Baggage Policies: Each airline has specific rules regarding baggage allowances, fees for extra bags, and restrictions on size and weight. Whether you're traveling light or with heavy luggage, understanding these policies helps you plan accordingly.</li>
                <li><FontAwesomeIcon icon={faCheckCircle} /> Special Assistance: Airlines offer a range of services for passengers needing special assistance. This includes wheelchair services, assistance for travelers with disabilities or medical conditions, and accommodations for unaccompanied minors. Knowing these options ensures a comfortable and stress-free journey for all travelers.</li>
                <li><FontAwesomeIcon icon={faCheckCircle} /> In-Flight Services: Discover the amenities available on board, such as entertainment options, Wi-Fi access, and meal services. Different airlines provide various levels of comfort and convenience, ensuring you choose the best option for your travel needs.</li>
                <li><FontAwesomeIcon icon={faCheckCircle} /> Travel Updates: Stay informed about flight status updates, delays, and cancellations. Our platform keeps you updated in real-time, allowing you to adjust your plans as needed and ensuring a smooth travel experience.</li>
              </ul>
            </div>
            <div className="book-a-flight-image">
              <img src={tkt} alt="Flight Booking" />
            </div>
          </div>

          <div className="book-a-flight-alternate-section">
            <div className="book-a-flight-image">
              <img src={comm} alt="Flight Booking" />
            </div>
            <div className="book-a-flight-text">
              <h2>Join Our Community</h2>
              <p>Sign up for exclusive deals and updates on airline tickets and travel packages. Be the first to know about our latest promotions and discounts.</p>
              {/* <button className="book-a-flight-button">Book Your Flight</button> */}
              <button
          className="plane-paradise-button"
          onClick={handlePhoneClick}
        >
          CALL TO GRAB DEAL
        </button>
            </div>
          </div>
        </div>

        <NumberPopup/>
      </div>
    </>
  );
};

export default BookAFlight;
