import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Footer from './Component/Footer/Footer.jsx';
import NotFound from './Component/Notfound/NotFound.jsx';
import Navbar from './Component/Navbar/Navbar.jsx';
import LandingPagesNavbar from './Component/LandingPageNavbar/LandingPageNavbar.jsx';
import AboutUs from './Component/AnoutUs/AboutUs.jsx';
import HomePage from './Component/HomePage/HomePage.jsx';
import Services from './Component/Services/Services.jsx';
import ContactUs from './Component/ContactUs/ContactUs.jsx';
import PrivacyPolicy from './Component/PrivacyPolicy/PrivacyPolicy.jsx';
import TermCondition from './Component/TermCondition/TermCondition.jsx';
import Cancellation from './Component/Cancellation/Cancellation.jsx';
// import Whatsapp from './Component/Whatsapp/Whatsapp.jsx';
import SearchResultPage from './Component/SearchResults/SearchResults.jsx';
import UserDetails from './Component/UserDetails/UserDetails.jsx';
import Confirmation from './Component/Confirmation/Confirmation.jsx';
import SearchResultss from './Component/SearchResultss/SearchResultss.jsx';
import RoundTrip from './Component/RoundTrip/RoundTrip.jsx';
import DepartureSearch from './Component/RoundTripSearch/RoundTripSearch.jsx';
import ArrivalSearch from './Component/RoundTripSearch/RoundTripSearch.jsx';
import NoFlightsFound from './Component/NoFlightsFound/NoFlightsFound.jsx';
// import MapComponent from './Component/ContactUs/MapComponent.jsx';
import PaymentForm from './Component/PaymentForm/PaymentForm.jsx';
// import CookieBanner from './Component/CookieBanner/CookieBanner.jsx';
import UserDetailsRoundTrip from './Component/UserDetails/UserDetailsRoundtrip.jsx';
import Loading from './Component/Loading/Loading.jsx';
import CustomizeTrip from './Component/CustomizeTrip/CustomizeTrip.jsx';
// import LandingPages from './Component/Landingpage/LandingPages.jsx';
import BookAFlight from './Component/BookAFlight/BookAFlight.jsx';
import CustomerService from './Component/CustomerService/CustomerService.jsx';
import VgroupBookingModification from './Component/VgroupBookingModification/VgroupBookingModification.jsx';
import UpgradeOption from './Component/UpgradeOption/UpgradeOption.jsx';
import PlaneParadise from './Component/PlaneParadise/PlaneParadise.jsx';
import LandingPage1 from './Component/Landingpage/LandingPage1.jsx';
import Cookies from './Component/CookieBanner/cookies.jsx';
import AirlineReservations from './Component/AirlineReservations/AirlineReservations.jsx';


// Custom hook to scroll to top on route change
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// Global conversion reporting function
window.gtag_report_conversion = function (url) {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url;
    }
  };
  if (typeof window.gtag !== 'undefined') {
    window.gtag('event', 'conversion', {
      send_to: 'AW-16610863777/0Sf6CJW4_70ZEKHV1vA9',
      event_callback: callback,
    });
  }
  return false; // Prevent default link behavior
};

const App = () => {
  const location = useLocation();

  // Determine whether to show LandingPagesNavbar based on the current route
  const isLandingPageRoute =
    location.pathname === '/Flight-Booking-Changes-cancellations' ||
    location.pathname === '/booking-modification' ||
    location.pathname === '/UpgradeOption' ||
    location.pathname === '/customer-service' ||
    location.pathname === '/expedia' ||
    location.pathname === '/Airline-Reservations' ||
    location.pathname === '/book-a-flight';


  return (
    <div>
      <Cookies/>
      {isLandingPageRoute ? <LandingPagesNavbar /> : <Navbar />}

      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/flightreservation" element={<HomePage />} />
        <Route exact path="/airlinetickets" element={<HomePage />} />
        <Route exact path="/flightdeals" element={<HomePage />} />
        <Route exact path="/seniortraveldeals" element={<HomePage />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact_us" element={<ContactUs />} />
        <Route path="/privacy&policy" element={<PrivacyPolicy />} />
        <Route path="/Terms&Condition" element={<TermCondition />} />
        <Route path="/cancellation_policies" element={<Cancellation />} />
        <Route path="/search_results" element={<SearchResultPage />} />
        <Route path="/user_details_form" element={<UserDetails />} />
        <Route path="/user_details_form_round_trip" element={<UserDetailsRoundTrip />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/search_resultss" element={<SearchResultss />} />
        <Route path="/round_trip" element={<RoundTrip />} />
        <Route path="/depart" element={<DepartureSearch />} />
        <Route path="/depart" element={<ArrivalSearch />} />
        <Route path="/no-flights-found" element={<NoFlightsFound />} />
        <Route path="/customise-trip" element={<CustomizeTrip />} />
        <Route path="/loading" element={<Loading />} />
        <Route path="/payments" element={<PaymentForm />} />
        <Route path="/Flight-Booking-Changes-cancellations" element={<LandingPage1 />} />
        <Route path="/book-a-flight" element={<BookAFlight />} />
        <Route path="/customer-service" element={<CustomerService />} />
        <Route path="/booking-modification" element={<VgroupBookingModification />} />
        <Route path="/UpgradeOption" element={<UpgradeOption />} />
        <Route path="/expedia" element={<PlaneParadise />} />
        <Route path="/Airline-Reservations" element={<AirlineReservations />} />
      </Routes>
      <Footer />
    </div>
  );
};

const AppWrapper = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default AppWrapper;
